<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Notifications</v-card-title>
        <v-card-subtitle>Sélectionnez les modes de notifications</v-card-subtitle>
        <v-card-text>
            <v-list>
                <v-list-item>
                    <v-list-item-title>Notifications par mail</v-list-item-title>
                    <v-switch v-model="notifications.mails" class="float-right" switch size="lg"/>
                    <v-btn icon class="ml-2">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>Notifications par SMS</v-list-item-title>
                    <v-switch v-model="notifications.sms" class="float-right" switch size="lg"/>
                    <v-btn icon class="ml-2">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" :disabled="isChanges()" @click="resetSettings">Annuler</v-btn>
            <v-btn color="success" :disabled="isChanges()" @click="saveSettings">Enregistrer</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name : 'Notifications',
    data() {
        return {
            notifications : JSON.parse(localStorage.getItem('settings')).preferencies.notifications,
            notifications_backup : JSON.parse(localStorage.getItem('settings')).preferencies.notifications,
        }
    },
    methods : {
        saveSettings() {
            if (JSON.stringify(this.notifications_backup) !== JSON.stringify(this.notifications)) {
                const settings = JSON.parse(localStorage.getItem('settings'));
                settings.preferencies.notifications = this.notifications;
                this.notifications_backup = this.notifications;
                localStorage.setItem('settings', JSON.stringify(settings));
            }
        },
        resetSettings() {
            this.notifications = Object.assign({}, this.notifications_backup);
        },
        isChanges () {
            if (JSON.stringify(this.notifications_backup) !== JSON.stringify(this.notifications)) {
                return false;
            } else {
                return true;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    
</style>